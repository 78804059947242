@media (min-width: 768px) {
  .fs-detail-container {
    max-width: 50vw;
    width: 100%;
  }
}

.fs-has-links {
  display: none;
}

.fs-wrapper {
  height: auto;
}

.fs-slider_v2_5 .fs-entry-container {
  height: 0 !important;
  width: 20% !important;
  padding-top: 20% !important;
}

.fs-wrapper .fs-text-container .fs-entry-title,
.fs-detail-title {
  font-family: inherit;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.fs-text-container .fs-entry-date,
.fs-detail-container .fs-post-info,
.fs-wrapper .fs-has-links::after,
.fs-text-product,
.fs-overlink-text {
  font-family: inherit;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.fs-slider-next-button,
.fs-slider-prev-button {
  opacity: 1 !important;
}

.fs-wrapper .fs-text-container * {
  color: #ffffff;
}

.fs-wrapper .fs-text-container {
  background-color: rgba(0, 0, 0, 0.8);
  margin: 0px;
}

.fs-entry-date {
  display: none;
}

.fs-entry-title {
  display: none;
}

.fs-slider_v2_5 .fs-wrapper .fs-timeline-entry {
  margin: 1px;
}

@media only screen and (max-width: 768px) {
  .fs-mobile .fs-wrapper .fs-entry-container {
    width: 222px !important;
    padding-top: 222px !important;
  }
}
